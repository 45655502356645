import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { clear_error } from '../../../store/actions/errorAction';
import { get_active_publications } from '../../../store/actions/publicationAction';
import Input from '../../Common/InputComponent';
import isEmpty from '../../../utils/isEmpty';
import { tagsValidator } from './TagsValidator';
import SuccessMessage from '../../Common/SuccessMessage';
import { createTag } from "../../../store/actions/tagAction";

export class AddTags extends Component {
    constructor(){
        super();
        this.state = {
            modalShow:false,
            errors : {},
            active_publications:[],
            parent : "",
            child : "",
            user_type : "",
            success: false,
            assigned_publications:[],
        }
    }

    /*******************************************
     * @DESC - RESET METHODS
     ******************************************/
    reset_method = () => {
        this.setState({
            modalShow:false,
            errors : {},
            active_publications:[],
            parent : "",
            child : "",
            user_type : "",
            assigned_publications:[],
            success: false
        });
    }

    /*******************************************
     * @DESC - MODAL TOGGLER METHODS
     *******************************************/
    modalToggler = () => {
        this.setState({
            modalShow : !this.state.modalShow
        })
    }
    modalViewHandler = view => e => {
        if( view === 2 ){
            let response = tagsValidator( this.state );
            if( response ){
                this.setState({
                    modalView : view
                })
            }
        } else {
            this.setState({
                modalView : view
            })
        }
    }

    /*******************************************
     * @DESC - LIFE CYCLE METHODS
     *******************************************/
    componentDidMount() {
        if( isEmpty( this.props.publications.active_publications ) ){
            this.props.get_active_publications();
        }
    }

    static getDerivedStateFromProps( nextProps, nextState ){
        if( nextProps.publications.active_publications !== nextState.active_publications ){
            return { active_publications : nextProps.publications.active_publications }
        }
        if( nextProps.errors !== nextState.errors ){
            return {
                errors : nextProps.errors
            }
        }
        return null;
    }

    /*******************************************
     * @DESC - LIFE CYCLE METHODS
     *******************************************/

    /*******************************************
     * @DESC - ONCHANGE METHODS
     *******************************************/
    onChange = e => {
        this.setState({
            [e.target.name] : e.target.value
        });
        this.props.clear_error();
    }

    /*******************************************
     * @DESC - CHECKBOX METHODS
     *******************************************/
    checkbox_handler = e => {
        this.setState({
            [e.target.name]:!this.state[e.target.name]
        })
    }

    /**********************************
     * @DESC - PUBLICATION HANDLER
     *********************************/
    onPublicationHandler = publication => e => {
        let assigned_publications = this.state.assigned_publications;
        let return_value = this.isPublicationPresent( publication );
        if( return_value || return_value === 0 ){
            assigned_publications.splice( return_value, 1 );
        } else {
            assigned_publications.push( publication );
        }
        this.setState({
            assigned_publications:assigned_publications
        })
    } 

    isPublicationPresent = ( publication  ) => {
        let obj = this.state.assigned_publications.find( prevPublication => prevPublication._id === publication._id );
        return obj ? this.state.assigned_publications.indexOf(obj) : false;
    }

    /**********************************
     * @DESC - ONSUBMIT METHODS
     *********************************/
    onSubmit = () => {
        let formData = {
            parent : this.state.parent,
            child : this.state.child,
            assigned_publications:this.state.assigned_publications,
        };

        let response = tagsValidator(formData);

        if(response) {
            if(window.confirm("Are you sure to create tag")) {

                // Transform assigned_publications into the desired format
                const publicationsArray = this.state.assigned_publications.map((item) => item._id);
                
                let payload = {
                    parent : this.state.parent,
                    name : this.state.child,
                    publication_ids : publicationsArray,
                };
                
                this.props.createTag(payload)
                .then((res) => {
                    if(res) {
                        this.setState({ modalShow: false, success : true })
                        // this.props.getTags()
                    } else {
                        alert("Something went wrong! Please try again")
                    }
                } )
            }
        } 
    }
    
    render() {
        return (
            <>{
                    !this.props.super_admin ? <div className='add_new_employee_label' onClick={ this.modalToggler }>Add New</div> : null
                }
                {
                    this.state.success ? 
                        <SuccessMessage 
                            message='Tag has been successfully created'
                            reset_method={ this.reset_method }
                        /> : null
                }
               {
                   this.props.super_admin ? <div className='add_personnel_main_container' onClick={ this.modalToggler }>+ Personnel</div>  : null
               } 
                <Modal show={ this.state.modalShow } size='sm' onHide={ this.modalToggler } >
                    <Modal.Body>
                        <BasicForm
                            state={ this.state }
                            errors={ this.state.errors }
                            onChange={ this.onChange }
                            auth={ this.props.auth }
                            checkbox_handler={ this.checkbox_handler }
                            onPublicationHandler={ this.onPublicationHandler }
                            isPublicationPresent={ this.isPublicationPresent }
                            modalViewHandler={ this.modalViewHandler }
                            onSubmit={ this.onSubmit }
                        />
                    </Modal.Body>
                </Modal> 
            </>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth,
    errors : state.errors.errors,
    publications : state.publications
});

export default connect( mapStateToProps, { createTag, clear_error, get_active_publications })(withRouter(AddTags));

export const BasicForm = ({
    state,
    onChange,
    errors,
    auth,
    checkbox_handler,
    onPublicationHandler,
    isPublicationPresent,
    modalViewHandler,
    onSubmit
}) => {
    return (
        <div className='basic_form_main_container'>
            <div className='add_personell_basic_label'>Create Tag</div>
            <div className='add_personnel_form'>
                <Input type='text' name='parent' value={ state.parent } error={ errors.parent } onChange={ onChange } placeholder='Enter parent tag name' />
                <Input type='text' name='child' value={ state.child } error={ errors.child } onChange={ onChange } placeholder='Enter child tag name' />
                <div className='assign_publication_label'>Assign publication</div>
                <div className='publication_assign_container'>
                    {
                        state.active_publications.map( ( data, index ) => (
                            <div key={ index } className='publication_data_container'>
                                <div className='publication_label'>{ data.publication_name }</div>
                                <div className='publication_input'><input onChange={ onPublicationHandler( data ) } checked={ ( isPublicationPresent( data ) || isPublicationPresent(data) === 0  ) ? true : false  } type='checkbox' /></div>
                            </div>
                        ) )
                    }
                </div>
                { errors.assigned_publications ? <div className='error'>{ errors.assigned_publications }</div> : null }
                <div className='space_top'></div>
                <div className='button_container'>
                    <button className='button_yellow' onClick={ onSubmit } >Create</button>
                </div>
            </div>
        </div>
    )
}