import React, { Component } from "react";
import NavItem from "../../Common/NavItem";
import { withRouter } from "react-router-dom"; // Import withRouter to access history
import AddTags from "./AddTags";
import { connect } from "react-redux";
import { getTags } from '../../../store/actions/tagAction';
import UpdateTag from "./UpdateTag";
import DeleteTag from "./DeleteTag";

class Tags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: this.props.tags
    };
  }

  async componentDidMount() {
    try {
      await this.props.getTags();
      this.setState({ tags: this.props.tags?.tagData })
    } catch (error) {
      console.error("Error fetching tags:", error);
    }
  }

  renderTree(node, level = 0) {

    if (!node) return null;

    const indent = level * 20; // Adjust the indent size as needed

    return (
      <ul>
        {Object.keys(node).map((key) => {
          const item = node[key];
          return (
            <li key={item._id} style={{ marginLeft: `${indent}px` }}>
              {item.name}
              {item.tags?.length > 0 && this.renderTree(item.tags, level + 1)}
            </li>
          );
        })}
      </ul>
    );
  };

  render() {

    const path = window.location.pathname;

    const SubNavItem = [
      { name: "Profile", to: "/profile" },
      { name: "Super admin", to: "/profile/super-admin" },
      { name: "Sub admin", to: "/profile/sub-admins" },
      { name: "Tags", to: "/profile/tags" },
      { name: "Calendar", to: "/profile/calendar" },
    ];

    return (
      <>
 
      <div className="row">
        <div className="col-sm-12">
          <div className="sub_navbar_container">
            {SubNavItem.map((data, index) => (
              <NavItem
              key={index}
              name={data.name}
              active={path === data.to ? "nav_item_black_active" : "nav_item_black"}
              borderRaduis={path === data.to}
              line={data.name === "Calendar" ? false : true}
              line_color_yellow={false}
              to={data.to}
              />
              ))}
          </div>
        </div>
      </div>

      <div className='row'>
          <div className='col-sm-12'>
              <div className='float-right'>
                 <AddTags />
                 <DeleteTag />
                 <UpdateTag />
              </div>
          </div>
          <div className='col-sm-12'>
              <div className='border_line'></div>
          </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="profile">
            <div className="profile_label">Tags</div>
              {this.renderTree(this.state.tags)}
          </div>
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  tags: state.tags,
  auth : state.auth,
  errors : state.errors.errors
});

export default connect( mapStateToProps, { getTags } )( withRouter(Tags) );