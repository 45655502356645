/************************************************
 *
 *   code is a mess Due to change in funcationality many time
 *  Need time to fix
 *
 * *********************************************
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SERVER_NAME, SERVER_TWO } from '../../../ServerUrl'
import { SubNavBar } from '../Profile/Profile';
import DragDrop from './DragDrop';
import {
	send_news_letter,
	news_letter_list,
	// newsletter_toggler,
	// display_newsletter_stories,
	// newsletter_data,
	// commentary_data,
} from '../../../store/actions/customerAction';
import { get_active_publications } from '../../../store/actions/publicationAction'
import isEmpty from '../../../utils/isEmpty';
import Modal from 'react-bootstrap/Modal';
import { parseISO, format } from 'date-fns';
const update = require('immutability-helper');
export class NewsLetter extends Component {
	constructor() {
		super();
		this.state = {
			all_newsLetter: [],
			all_commentary: [],
			value: '',
			newsletter_btn: true,
			modalShow:false,
			title:"Global Recycling",
			publication_name:"Global Recycling",
			publication_id:"5d8dbfb374fcac1e902dd523",
		};
	}

	componentDidMount() {
		this.props.news_letter_list({id: "5d8dbfb374fcac1e902dd523", name: "Global Recycling"}); // default call for recycling publication
		this.props.get_active_publications();
		// this.props.display_newsletter_stories();
	}

	sendAsianNewsLetter = () => {
		let path = `${SERVER_NAME}/admin/posts/send-asia-newsletter`;	
		if (window.confirm('Do you really want Send NewsLetter?')) {
			this.setState({
				newsletter_btn: false,
			});
			let formData = {
				link_to_be_sent: this.state.value,
				newsletters: this.state.all_newsLetter,
				commentary: this.state.all_commentary,
				publication_name: this.state.publication_name,
				publication_id:  this.state.publication_id
			};
			this.props.send_news_letter(formData, path);
		}
	};

	sendGlobalNewsLetter = () => {
		let path = `${SERVER_NAME}/admin/posts/send-global-newsletter`;
		if (window.confirm('Do you really want Send NewsLetter?')) {
			this.setState({
				newsletter_btn: false,
			});
			let formData = {
				link_to_be_sent: this.state.value,
				newsletters: this.state.all_newsLetter,
				commentary: this.state.all_commentary,
				publication_name: this.state.publication_name,
				publication_id:  this.state.publication_id
			};
			this.props.send_news_letter(formData, path);
		}
	};

	// toggle = (id) => {
	// 	const postid = {
	// 		id: id,
	// 	};
	// 	this.props.newsletter_toggler(postid);
	// 	this.props.news_letter_list();
	// 	// this.props.display_newsletter_stories();
	// 	window.location.reload();
	// };

	/************************************
	 * Add Commentery for newsletter
	 ************************************/
	commentary = ({ id, text }) => {
		let all_commentary = this.state.all_commentary;

		if (this.is_present(all_commentary, id)) {
			//all_commentary =  this.arrayRemove(all_commentary, id) //for checkbox
			window.alert('Already exist');
		} else {
			all_commentary.push({ id: id, text: text });
		}
		this.setState(
			{
				all_commentary: all_commentary,
			}
			//,
			// () => {
			// 	let formData = {
			// 		all_commentary: all_commentary,
			// 	};
			// 	// this.props.commentary_data(formData);
			// }
		);
	};

	/************************************
	 * Add News for newsletter
	 ************************************/
	news = ({ id, text }) => {
		let all_newsLetter = this.state.all_newsLetter;

		if (this.is_present(all_newsLetter, id)) {
			//all_newsLetter = this.arrayRemove(all_newsLetter, id) //for checkbox
			window.alert('Already exist');
		} else {
			all_newsLetter.push({ id: id, text: text });
		}
		this.setState(
			{
				all_newsLetter: all_newsLetter,
			}
			// ,
			// () => {
			// 	let formData = {
			// 		all_newsLetter: all_newsLetter,
			// 	};
			// 	// this.props.newsletter_data(formData);
			// }
		);
	};

	// is_dpc_present = data => {
	//   let obj = this.state.all_commentary.find(category => category === data);
	//   return obj ? this.state.all_commentary.indexOf(obj) : false;
	// };

	/************************************
	 * Check if the article is present in the list or not
	 ************************************/
	is_present = (array, id) => {
		let present;
		if (!isEmpty(array)) {
			present = array.find((article) => article.id == id);
		} else {
			return false;
		}
		return present ? true : false;
	};

	/************************************
	 * Remove article from Newsletter
	 ************************************/
	//For check box
	arrayRemove = (arr, value) => {
		console.log(arr, value);

		var articles = arr.filter(function (arr) {
			return arr.id !== value;
		});
		return articles;
	};

	//In use
	arrayRemoveCross = (arr, value) => {
		console.log(arr, value);

		switch (arr) {
			case 'commentary':
				var articles = this.state.all_commentary.filter(function (arr) {
					return arr.id !== value;
				});
				this.setState({
					all_commentary: articles,
				});
				break;
			case 'news':
				var articles = this.state.all_newsLetter.filter(function (arr) {
					return arr.id !== value;
				});
				this.setState({
					all_newsLetter: articles,
				});
				break;

			default:
				return { ...this.state };
				break;
		}
	};

	/************************************
	 * Pdf input event
	 ************************************/
	handleChange = (event) => {
		this.setState({ value: event.target.value });
	};

	/************************************
	 * Drag news articles
	 ************************************/
	moveNews = (dragIndex, hoverIndex) => {
		const { all_newsLetter } = this.state;
		const dragCard = all_newsLetter[dragIndex];
		this.setState(
			update(this.state, {
				all_newsLetter: {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, dragCard],
					],
				},
			})
		);
	};

	/************************************
	 * Drag commentary articles
	 ************************************/
	moveCommentary = (dragIndex, hoverIndex) => {
		const { all_commentary } = this.state;
		const dragCard = all_commentary[dragIndex];
		this.setState(
			update(this.state, {
				all_commentary: {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, dragCard],
					],
				},
			})
		);
	};

	/****************************
     * @DESC - MODAL TOGGLER
     ****************************/
    modalToggler = e => {
		if (this.state.value) {
			this.setState({
				modalShow : !this.state.modalShow
			})
		}else{
			alert("Please add PDF link")
		}
	}

	/****************************
     * @DESC - GET POST BY PUBLICATION
     ****************************/
    getPublication = (id, name) => {
		this.setState({
			title: name,
			all_newsLetter: [],
			all_commentary: [],
			value: '',
			newsletter_btn: true,
			publication_name:name,
			publication_id:id,
		})
		let publication = {id, name}
		console.log(publication);
		this.props.news_letter_list(publication)

	}
	



	render() {
		return (
			<>
			<div className="container-fluid">


			<div className="publication_container">
				{
					!isEmpty(this.props.publications.active_publications)? 
					
					this.props.publications.active_publications.map( publications =>(
					<div className="Publication_name" key={ publications._id } onClick={()=>{this.getPublication(publications._id, publications.publication_name)}}>{publications.publication_name}</div>
					))

					:null

				}
			</div>

					{/* <SubNavBar
	      path={this.props.history.location.pathname}
	      user={this.props.auth.user}
	    /> */}
	
					{/* news letter btn active inactive */}
		<Heading heading={`${this.state.title} Stories`} />
					<div className="send_btn_container">
						<div className="pdf_input">
							<input
								type="text"
								placeholder="Add PDF Path    ex. https://view.davisindex.com"
								value={this.state.value}
								onChange={this.handleChange}
								required
							/>
						</div>
	
						{this.state.newsletter_btn ? (
							<div className="add_newsletter_btn" onClick={ this.modalToggler }>
								Send Newsletter	
							</div>
						) : (
							<div className="add_newsletter_btn_dis">Send Newsletter</div>
						)}
					</div>
	
					{/* News letter added News and commentary &  Drag area */}
					<NewsCommentary
						// data_newsletter={this.props.data_newsletter}
						// data_commentary={this.props.data_commentary}
						arrayRemoveCross={this.arrayRemoveCross}
						moveNews={this.moveNews}
						moveCommentary={this.moveCommentary}
						state={this.state}
					/>
	
					{/* All news letter Stories */}
					<NewsLetterList
						toggle={this.toggle}
						commentary={this.commentary}
						news={this.news}
						newsLetterList={this.props.newsLetterList}
					/>
					{/* <Heading heading="Add Stories TO Newsletter" />
			<NewsLetterStories
				toggle={this.toggle}
				newsLetterList={this.props.newsletter_stories}
			/> */}
				</div>
				<Modal show={ this.state.modalShow } size='md' onHide={ this.modalToggler } centered >
                    <Modal.Body>
                     <div>
						 <h3 className="text-center"> Select newsletter type</h3>
                     	  <div className="btn_container">
							   
						   {this.state.newsletter_btn ? (
								<>
									<div className="add_newsletter_btn_40" onClick={this.sendAsianNewsLetter} >Asia Newsletter</div>
									<div className="add_newsletter_btn_40" onClick={this.sendGlobalNewsLetter} >Global Newsletter</div>
								</>
							) : (
								<>
									<div className="add_newsletter_btn_dis_40">Asia Newsletter</div>
									<div className="add_newsletter_btn_dis_40">Global Newsletter</div>
								</>
							)}

						   </div>
                     </div>
                    </Modal.Body>
                </Modal> 
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	publications: state.publications,
	newsLetterList: state.customers.news_letter_list,
	// newsletter_stories: state.customers.newsletter_stories,
	data_newsletter_aaa: state,
	// data_newsletter: state.customers.newsletter_data,
	// data_commentary: state.customers.commentary_data,
});

export default connect(mapStateToProps, {
	send_news_letter,
	news_letter_list,
	// newsletter_toggler,
	// display_newsletter_stories,
	// newsletter_data,
	// commentary_data,
	get_active_publications
})(withRouter(NewsLetter));

export const NewsCommentary = (props) => {
	return (
		<>
			<div className="row news_commentary">
				<div className="col-md-6">
					{!isEmpty(props.state.all_commentary) ? (
						<>
							<Heading heading="Commentary" />
							<DragDrop
								moveArticles={props.moveCommentary}
								articles={props.state.all_commentary}
								arrayRemoveCross={props.arrayRemoveCross}
								articleType="commentary"
							/>
						</>
					) : null}
					{/* {
          !isEmpty(props.data_commentary) ? props.data_commentary.map((data, index)=>(
            <div className="headline_container_render" key={index}>{data.headline}</div>
            ) )
          : <p className="text-center">Add To Commentary</p>} */}
				</div>
				<div className="col-md-6">
					{!isEmpty(props.state.all_newsLetter) ? (
						<>
							<Heading heading="News" />
							<DragDrop
								moveArticles={props.moveNews}
								articles={props.state.all_newsLetter}
								arrayRemoveCross={props.arrayRemoveCross}
								articleType="news"
							/>
						</>
					) : null}
					{/* {
            !isEmpty(props.data_newsletter) ? props.data_newsletter.map((data, index)=>(
            <div className="headline_container_render" key={index}>{data.headline}</div>
            ) )
          : <p className="text-center">Add To News</p>} */}
				</div>
				{!isEmpty(props.state.all_commentary) ||
				!isEmpty(props.state.all_newsLetter) ? null : (
					<div className="create_newsletter">
						<h1>Create Newsletter</h1>
					</div>
				)}
			</div>
		</>
	);
};

export const NewsLetterList = (props) => {
	console.log(props);
	return (
		<div className="view_panel_main_container">
			<div className="left_view_panel_container">
				{!isEmpty(props.newsLetterList) ? (
					props.newsLetterList.map((data, index) => (
						<RenderNews
							toggle={props.toggle}
							commentary={props.commentary}
							news={props.news}
							data={data}
							key={index}
						/>
					))
				) : (
					<RenderEmpryNews />
				)}
			</div>
		</div>
	);
};

// export const NewsLetterStories = (props) => {
// 	return (
// 		<div className="view_panel_main_container">
// 			<div className="left_view_panel_container">
// 				{!isEmpty(props.newsLetterList) ? (
// 					props.newsLetterList.map((data, index) => (
// 						<RenderNewsStories toggle={props.toggle} data={data} key={index} />
// 					))
// 				) : (
// 					<RenderEmpryNews />
// 				)}
// 			</div>
// 		</div>
// 	);
// };

export const RenderNews = (props) => {
	let content_created_date = '';
	if (!isEmpty(props.data.content_to_be_published_on_date)) {
		content_created_date = format(
			parseISO(
				props.data.content_to_be_published_on_date,
				'yyyy-MM-dd',
				new Date()
			),
			//"dd-MMM-yyyy"
			'dd-MMM-yyyy hh:mm a'
		);
	}
	return (
		<div className="render_display_page_main_container">
			<div className="headline_container_render">
				{props.data.headline}
				<div className="date_time_container">{content_created_date}</div>
			</div>
			{/* <div className="add_remove_container">
        <button  type="button"  className="add_remove_btn" onClick={() => props.toggle(props.data._id)}>
          Remove
        </button>
      </div> */}
			<div className="add_remove_container">
				<button
					className="add_remove_btn"
					onClick={() =>
						props.commentary({ id: props.data._id, text: props.data.headline })
					}>
					Add To Commentary
				</button>
			</div>
			<div className="add_remove_container">
				<button
					type="button"
					className="add_remove_btn"
					onClick={() =>
						props.news({ id: props.data._id, text: props.data.headline })
					}>
					Add To News
				</button>
			</div>
		</div>
	);
};

// export const RenderNewsStories = (props) => {
// 	let content_created_date = '';
// 	if (!isEmpty(props.data.content_to_be_published_on_date)) {
// 		content_created_date = format(
// 			parseISO(
// 				props.data.content_to_be_published_on_date,
// 				'yyyy-MM-dd',
// 				new Date()
// 			),
// 			//"dd-MMM-yyyy"
// 			'dd-MMM-yyyy hh:mm a'
// 		);
// 	}
// 	return (
// 		<div className="render_display_page_main_container">
// 			<div className="headline_container_render">{props.data.headline}</div>
// 			<div className="date_time_container">
// 				<div>
// 					<i className="fa fa-calendar mr-1" aria-hidden="true"></i>
// 				</div>
// 				<div>{content_created_date}</div>
// 			</div>
// 			<div className="add_remove_container">
// 				<div
// 					className="add_remove_btn"
// 					onClick={() => props.toggle(props.data._id, props.data.headline)}>
// 					Add
// 				</div>
// 			</div>
// 		</div>
// 	);
// };

export const RenderEmpryNews = (props) => {
	return (
		<div className="empty_news">
			<h1>You're all caught up!</h1>
		</div>
	);
};

export const Heading = (props) => {
	return (
		<div className="container">
			<h2 className="newsletter_heading"> {props.heading}</h2>
		</div>
	);
};
