import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import NavItem from "../../Common/NavItem";
import { format, parseISO } from "date-fns";
import TermsAndCondition from "../TermsAndCondition/TermsAndCondition";
import ChangePassword from "../ChangePassword/ChangePassword";
import Tags from "../Tags/Tags";

export class Profile extends Component {
  render() {
    return (
      <div className="container-fluid">
        {!this.props.auth.user.hasUserLoggedInEver ? (
          <TermsAndCondition modalShow={true} />
        ) : null}
        <SubNavBar
          path={this.props.history.location.pathname}
          user={this.props.auth.user}
        />
        <div className="row">
          <div className="col-sm-12">
            <div className="margin_top"></div>
          </div>
        </div>
        {/* <NameLabel user={this.props.auth.user} /> */}
        <ProfileSection user={this.props.auth.user} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {})(withRouter(Profile));

const SubNavItem = [
  { name: "Profile", to: "/profile" },
  { name: "Super admin", to: "/profile/super-admin" },
  { name: "Sub admin", to: "/profile/sub-admins" },
  { name: "Tags", to: "/profile/tags" },
  // { name: "Newsletter", to: "/profile/news-letter" },
  { name: "Calendar", to: "/profile/calendar" }
];
export const SubNavBar = ({ path, user }) => {
  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="sub_navbar_container">
          {SubNavItem.map((data, index) => {
            if (
              !user?.isSuperAdmin &&
              (data?.name === "Super admin" || data?.name === "Sub admin" || data?.name === "Calendar" || data?.name === "Tags")
              // (data.name === "Super admin" || data.name === "Sub admin" || data.name === "Newsletter")
            ) {
              return null;
            } else {
              return (
                <NavItem
                  key={index}
                  name={data.name}
                  active={
                    path === data.to
                      ? "nav_item_black_active"
                      : "nav_item_black"
                  }
                  borderRaduis={path === data.to ? true : false}
                  line={data.name === "Calendar" ? false : true}
                  line_color_yellow={false}
                  to={data.to}
                />
              );
            }
          })}
        </div>
        {/* <div className="border_line"></div> */}
      </div>
    </div>
  );
};

export const NameLabel = ({ user }) => {
  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="profile_name_label">
          {user.fname}&nbsp;{user.lname}
        </div>
      </div>
    </div>
  );
};

export const ProfileSection = ({ user }) => {
  return (
    <div className="row">
          <ProfileContainer user={user} />
          <ViewPublication user={user} />
          <ViewRoles user={user} />
          <Stats user={user} />
    </div>
  );
};

export const ProfileContainer = ({ user }) => {
  const active_date = parseISO(user.account_created_at_date);
  return (
   <div className="col-md-6">
      <div className="profile">
        <div className="profile_label">Profile</div>
        <div className="active_since">
          {" "}
          Active Since {format(active_date, "dd MMM yyyy")}
        </div>
        <div className="profile_email">{user.email}</div>
        {/* <div className='profile_terms_and_condition'>{ "Terms and Conditions" }</div> */}
        <TermsAndCondition />
        <div className="update_profile">
          <ChangePassword />
        </div>
      </div>
   </div>
  );
};

export const ViewPublication = ({ user }) => {
  return (
    <div className="col-md-6">
      <div className="publications_section">
        <div className="publication_label">Assigned publications</div>
        {user.assigned_publications.map((data, index) => (
          <div key={index} className="publication_name">
            {data.publication_name}
          </div>
        ))}
      </div>
    </div>
  );
};

export const ViewRoles = ({ user }) => {
  return (
   <div className="col-md-6">
      <div className="roles">
        <div className="roles_label">Basic Roles</div>
        {user.isEditorialJournalist ? (
          <div className="roles_list">Editorial Journalist</div>
        ) : null}
        {user.isPricingReporter ? (
          <div className="roles_list">Pricing Reporter</div>
        ) : null}
        <div className="roles_list text-capitalize">{user.user_type}</div>
        {user.isAdministrator ? (
          <div className="roles_label">Administrator Roles</div>
        ) : null}
        {user.isAdministrator_a_rolemanager ? (
          <div className="roles_list">Role Call Manager</div>
        ) : null}
        {user.isAdministrator_a_Customer_Manager ? (
          <div className="roles_list">Customer Manager</div>
        ) : null}
        {user.isAdministrator_a_PricingManager ? (
          <div className="roles_list">Pricing Manager</div>
        ) : null}
      </div>
   </div>
  );
};

export const Stats = ({ user : { status} }) => {
  return (
    <div className="col-md-6">
      <div className="stats">
        <div className="stats_label">Stats</div>
        <div className="d-flex justify-content-between mt-4">
          <div className="stats_list">Status</div>
          <div className="stats_list">{status}</div>
        </div>
      </div>
    </div>
  );
};
