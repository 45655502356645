import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { clear_error } from "../../../store/actions/errorAction";
import Input from "../../Common/InputComponent";
import { deleteTagValidator } from "./TagsValidator";
import SuccessMessage from "../../Common/SuccessMessage";
import { deleteTag } from "../../../store/actions/tagAction";

export class DeleteTag extends Component {
  constructor() {
    super();
    this.state = {
      modalShow: false,
      errors: {},
      tagName: "",
      user_type: "",
      success: false,
    };
  }

  /*******************************************
   * @DESC - RESET METHODS
   ******************************************/
  reset_method = () => {
    this.setState({
      modalShow: false,
      errors: {},
      tagName: "",
      user_type: "",
      success: false,
    });
  };

  /*******************************************
   * @DESC - MODAL TOGGLER METHODS
   *******************************************/
  modalToggler = () => {
    this.setState({
      modalShow: !this.state.modalShow,
    });
  };

  modalViewHandler = (view) => (e) => {
    if (view === 2) {
      let response = deleteTagValidator(this.state);
      
      if (response) {
        this.setState({
          modalView: view,
        });
      }
    } else {
      this.setState({
        modalView: view,
      });
    }
  };

  /*******************************************
   * @DESC - ONCHANGE METHODS
   *******************************************/
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.props.clear_error();
  };

  /**********************************
   * @DESC - PUBLICATION HANDLER
   *********************************/
  onPublicationHandler = (publication) => (e) => {
    let assigned_publications = this.state.assigned_publications;
    let return_value = this.isPublicationPresent(publication);
    if (return_value || return_value === 0) {
      assigned_publications.splice(return_value, 1);
    } else {
      assigned_publications.push(publication);
    }
    this.setState({
      assigned_publications: assigned_publications,
    });
  };

  findElementByName(obj, name) {
    for (const key in obj) {
      if (obj[key].name === name) {
        return obj[key];
      } else if (obj[key].tags && obj[key].tags.length > 0) {
        const result = this.findElementByName(obj[key].tags, name);
        if (result) {
          return result;
        }
      }
    }
    return null; // Element not found
  }

  /**********************************
   * @DESC - ONSUBMIT METHODS
   *********************************/
  onSubmit = () => {
    
    let response = deleteTagValidator(this.state.tagName);

    const element = this.findElementByName(this.props.tags?.tagData, this.state.tagName);
    if (response && element) {
      if (window.confirm("Are you sure to delete tag")) {
        const payload = { name: element.name.trim() }
        this.props.deleteTag(payload)
        .then((res) => {
          if(res) {
            this.setState({ modalShow: false, success: true });
          } else {
            alert("Something went wrong! Please try again");
          }
        })
      }
    } else {
      alert("Tag not found");
    }
  };

  render() {
    return (
      <>
        {!this.props.super_admin ? (
          <div className="add_new_employee_label" onClick={this.modalToggler}>
            Delete Tag
          </div>
        ) : null}
        {this.state.success && this.props.auth.success ? (
          <SuccessMessage
            message="Tag has been successfully created"
            reset_method={this.reset_method}
          />
        ) : null}
        <Modal show={this.state.modalShow} size="sm" onHide={this.modalToggler}>
          <Modal.Body>
            <BasicForm
              state={this.state}
              errors={this.state.errors}
              onChange={this.onChange}
              auth={this.props.auth}
              onPublicationHandler={this.onPublicationHandler}
              modalViewHandler={this.modalViewHandler}
              onSubmit={this.onSubmit}
            />
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  tags: state.tags,
  auth: state.auth,
  errors: state.errors.errors,
});

export default connect(mapStateToProps, { clear_error, deleteTag })(withRouter(DeleteTag));

export const BasicForm = ({
  state,
  onChange,
  errors,
  auth,
  checkbox_handler,
  onPublicationHandler,
  isPublicationPresent,
  modalViewHandler,
  onSubmit,
}) => {
  return (
    <div className="basic_form_main_container">
      <div className="add_personell_basic_label">Delete Tag</div>
      <div className="add_personnel_form">
        <Input
          type="text"
          name="tagName"
          value={state.tagName}
          error={errors.tagName}
          onChange={onChange}
          placeholder="Enter tag name"
        />
        <div className="space_top"></div>
        <div className="button_container">
          <button className="button_yellow" onClick={onSubmit}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};
