import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { clear_error } from '../../../store/actions/errorAction';
import { get_active_publications } from '../../../store/actions/publicationAction';
import Input from '../../Common/InputComponent';
import isEmpty from '../../../utils/isEmpty';
import { updateTagValidator } from './TagsValidator';
import SuccessMessage from '../../Common/SuccessMessage';
import { updateTag } from '../../../store/actions/tagAction';

export class UpdateTag extends Component {
    constructor(){
        super();
        this.state = {
            modalShow:false,
            errors : {},
            tagName : "",
            newTagName : "",
            user_type : "",
            success: false,
        }
    }

    /*******************************************
     * @DESC - RESET METHODS
     ******************************************/
    reset_method = () => {
        this.setState({
            modalShow:false,
            errors : {},
            tagName : "",
            newTagName : "",
            user_type : "",
            success: false
        });
    }

    /*******************************************
     * @DESC - MODAL TOGGLER METHODS
     *******************************************/
    modalToggler = () => {
        this.setState({
            modalShow : !this.state.modalShow
        })
    }
    modalViewHandler = view => e => {
        this.setState({
            modalView : view
        })
    }

    /*******************************************
     * @DESC - ONCHANGE METHODS
     *******************************************/
    onChange = e => {
        this.setState({
            [e.target.name] : e.target.value
        });
        this.props.clear_error();
    }

    /*******************************************
     * @DESC - CHECKBOX METHODS
     *******************************************/
    checkbox_handler = e => {
        this.setState({
            [e.target.name]:!this.state[e.target.name]
        })
    }

    /**********************************
     * @DESC - PUBLICATION HANDLER
     *********************************/
    onPublicationHandler = publication => e => {
        let assigned_publications = this.state.assigned_publications;
        let return_value = this.isPublicationPresent( publication );
        if( return_value || return_value === 0 ){
            assigned_publications.splice( return_value, 1 );
        } else {
            assigned_publications.push( publication );
        }
        this.setState({
            assigned_publications:assigned_publications
        })
    } 

    isPublicationPresent = ( publication  ) => {
        let obj = this.state.assigned_publications.find( prevPublication => prevPublication._id === publication._id );
        return obj ? this.state.assigned_publications.indexOf(obj) : false;
    }

    findElementByName(obj, name) {
        for (const key in obj) {
          if (obj[key].name === name) {
            return obj[key];
          } else if (obj[key].tags && obj[key].tags.length > 0) {
            const result = this.findElementByName(obj[key].tags, name);
            if (result) {
              return result;
            }
          }
        }
        return null; // Element not found
    }

    /**********************************
     * @DESC - ONSUBMIT METHODS
     *********************************/
    onSubmit = () => {
        let formData = {
            tagName : this.state.tagName,
            newTagName : this.state.newTagName,
        };

        let response = updateTagValidator(formData);

        const elementId = this.findElementByName(this.props.tags.tagData, this.state.tagName);

        if(response && elementId) {
            if(window.confirm("Are you sure to update tag")) {
                const payload = { name: this.state.newTagName };
                this.props.updateTag(payload, elementId._id);
                this.setState({ modalShow: false, success : true });
            }
        }
    }


    render() {
        return (
            <>{
                    !this.props.super_admin ? <div className='add_new_employee_label' onClick={ this.modalToggler }>Update Tag</div> : null
                }
                {
                    this.state.success ? 
                        <SuccessMessage 
                            message='Tag has been updated successfully'
                            reset_method={ this.reset_method }
                        /> : null
                }
                <Modal show={ this.state.modalShow } size='sm' onHide={ this.modalToggler } >
                    <Modal.Body>
                        <BasicForm
                            state={ this.state }
                            errors={ this.state.errors }
                            onChange={ this.onChange }
                            auth={ this.props.auth }
                            checkbox_handler={ this.checkbox_handler }
                            onPublicationHandler={ this.onPublicationHandler }
                            isPublicationPresent={ this.isPublicationPresent }
                            modalViewHandler={ this.modalViewHandler }
                            onSubmit={ this.onSubmit }
                        />
                    </Modal.Body>
                </Modal> 
            </>
        )
    }
}

const mapStateToProps = state => ({
    tags: state.tags,
    auth : state.auth,
    errors : state.errors.errors,
});

export default connect( mapStateToProps, { clear_error, get_active_publications, updateTag })(withRouter(UpdateTag));

export const BasicForm = ({
    state,
    onChange,
    errors,
    auth,
    checkbox_handler,
    onPublicationHandler,
    isPublicationPresent,
    modalViewHandler,
    onSubmit
}) => {
    return (
        <div className='basic_form_main_container'>
            <div className='add_personell_basic_label'>Update Tag</div>
            <div className='add_personnel_form'>
                <Input type='text' name='tagName' value={ state.tagName } error={ errors.tagName } onChange={ onChange } placeholder='Enter old tag name' />
                <Input type='text' name='newTagName' value={ state.newTagName } error={ errors.newTagName } onChange={ onChange } placeholder='Enter new tag name' />
                <div className='space_top'></div>
                <div className='button_container'>
                    <button className='button_yellow' onClick={ onSubmit } >Create</button>
                </div>
            </div>
        </div>
    )
}