import {
    GET_TAGS,
    UPDATE_TAG,
    DELETE_TAG,
    CREATE_TAG,
    CLEAR_LOADER,
    SET_SUCCESS,
    SET_LOADER,
    CLEAR_SUCCESS,
    SET_TAGS
  } from "../types";

const initialState = {
}

export default function ( state = initialState, action ){
    switch (action.type) {

        case SET_TAGS:
        return {
            ...state,
            loader : false,
            success : true,
            tagData: { ...action.payload }
        }
        case GET_TAGS:
            return {
                ...state,
                state,
            }
        case CLEAR_LOADER : 
            return {
                ...state,
                loader : false
            }
        case SET_SUCCESS : 
            return {
                ...state,
                success : true
            }
        case CLEAR_SUCCESS :
            return {
                ...state,
                success : false
            }
        default :
            return state
    }
}