import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; 
import store from './store/store';
import PrivateRoute from './utils/PrivateRoute';
import PrivateRouteTwo from './utils/PrivateRouteTwo';

import EmailVerification from './Components/EmailVerification/EmailVerification';
import ForgetPassword from './Components/ForgetPassword/ForgetPassword';
import ResetPassword from './Components/ResetPassword/ResetPassword';
import Login from './Components/LoginPage/Login';
import HealthCheck from './Components/Common/Healthcheck';
// PROFILE
import Profile from './Components/Profile/Profile/Profile';
import SuperAdmin from './Components/Profile/SuperAdmin/SuperAdmin';
import SubAdminList from './Components/Profile/SubAdmins/SubAdmin';
import Calendar from './Components/Profile/Calendar/Calendar';
import Tags from './Components/Profile/Tags/Tags';
import NewsLetter from './Components/Profile/NewsLetter/NewsLetter';

// Administrator
import AdministratorEditorialJournalist from './Components/Adminstrator/Editorial/Journalists/Journalists';
import AdministratorEditorialPricingReporter from './Components/Adminstrator/Editorial/PricingReporter/PricingReporter';
import AdministratorEditorialEmails from './Components/Adminstrator/Editorial/EditorialEmails/EditorialEmails';

// Supereditors
import SuperEditors from './Components/SuperEditors/SuperEditors'

import Methodology from './Components/Adminstrator/Methodology/DisplayMethodology';
import ActiveSources from './Components/Adminstrator/Sources/ActiveSources';
import ArchiveSources from './Components/Adminstrator/Sources/ArchiveSources';
import SourcePerson from './Components/Adminstrator/Sources/DisplaySourcePerson/DisplaySourcePerson';

//promotion code
import GetAllPromotionCodes from "./Components/Adminstrator/Customers/PromotionCode/AllPromotionCodes";

import MarketActive from './Components/Adminstrator/Markets/Active/Active';
import MarketArchives from './Components/Adminstrator/Markets/Archives/Archives';
import MarketNotices from './Components/Adminstrator/Markets/Notices/Notices';
import MarketAddPrice from './Components/Adminstrator/Markets/Price/AddPrices/AddPrices';
import MarketUpdatePrices from './Components/Adminstrator/Markets/Price/UpdatePrices/UpdatePrices';

import ActiveOrganisationCustomer from './Components/Adminstrator/Customers/ActiveCustomers/ActiveCustomer';
import ArchiveOrganisationCustomer from './Components/Adminstrator/Customers/ArchivedCustomers/ArchivedCustomers';
import SingleOrgansationCustomer from './Components/Adminstrator/Customers/SingleOrganisationDetails/SingleOrganisationDetails';
import addUser from './Components/Adminstrator/Customers/CreateUsers/CreateUsers';
import PaidUser from './Components/Adminstrator/Customers/Users/PaidUser';
import TrialUser from './Components/Adminstrator/Customers/Users/TrialUser';
import SingleUser from './Components/Adminstrator/Customers/Users/SingleUser';
import ReferralSingleUser from './Components/Adminstrator/Customers/Users/ReferralSingleUser';
import ExpiredSingleUser from './Components/Adminstrator/Customers/Users/ExpiredSingleUser';
import ArchivedUser from './Components/Adminstrator/Customers/Users/ArchivedUser';
import ExpiredUser from './Components/Adminstrator/Customers/Users/ExpiredUser';
import ReferralCode from './Components/Adminstrator/Customers/Users/ReferralCode';

import ManageTradelog from './Components/Adminstrator/TradelogManage/TradelogManage';
import TradelogView from './Components/Adminstrator/TradelogManage/Tradelog';
// UPDATE LINK
import UpdateMethodology from './Components/Adminstrator/UpdateLinks/UpdateMethodology';
// import AddNewPrices from './Components/Adminstrator/Markets/Prices/AddPrices/AddNewPrices';

//All Stories
import AllStories from './Components/AllStories/AllStories'

// EDITOR
import MyWorkHOC from "./Components/Editorials/Editor/Common/MyWorkHOC";
import InReviewHOC from "./Components/Editorials/Editor/Common/InReviewHOC";
import EditHOC from "./Components/Editorials/Editor/Common/EditHOC"
import DesignerPublishedData from './Components/Editorials/Designer/PublishedData';
import DesignedViewData from './Components/Editorials/Designer/ViewData';

//Author
import DraftHOC from "./Components/Editorials/Author/Common/DraftHOC";
import PublishedHOC from "./Components/Editorials/Author/Common/PublishedHOC";
 

// POST
import AuthorPostArea from './Components/Editorials/Post/AuthorPostArea/AuthorPostArea';
import EditorPostArea from './Components/Editorials/Post/EditorPostArea/EditorPostArea';

// PRICES
import AuthorTradeLog from './Components/Prices/Author/TradeLog';
import AuthorInReview from './Components/Prices/Author/InReview';
import AuthorPublishedPrices from './Components/Prices/Author/PublishedPrices';
import AuthorPublishedList from './Components/Prices/Author/ViewPublished';
import AuthorPublishedViewDate from './Components/Prices/Author/ViewPublishedDate';
import PriceActiveSources from './Components/Prices/Sources/ActiveSources';
import PriceArchiveSources from './Components/Prices/Sources/ArchiveSources';
import PriceSourcePerson from './Components/Prices/Sources/DisplaySourcePerson/DisplaySourcePerson';
import PriceEditorInReview from './Components/Prices/Editor/InReview';
import SuperEditor from './Components/Prices/SuperEditor/SuperEditor';
import ExcelSheetTradelog from './Components/Prices/SuperEditor/ExcelPriceSheet';
// import PriceEditorPublished from './Components/Prices/Editor/Published';
import PriceArchives from './Components/Prices/Archives/Archives';
import PriceArchivesList from './Components/Prices/Archives/ArchiveList';
import PriceArchivesView from './Components/Prices/Archives/ViewArchive';
import PriceArchivesViewDate from './Components/Prices/Archives/ViewArchiveDate';

// PRICE SHEETS
import AssesmentRange from './Components/Prices/PriceSheets/AssesmentRangePriceSheet';
import PriceAuthorInReview from './Components/Prices/PriceSheets/AssesmentRangePriceSheet';
import ViewInReview from './Components/Prices/Author/ViewInReview';
import AssesmentRangeEditor from './Components/Prices/PriceSheetEditor/AssesmentRangePriceSheetEditor';
// import AssesmentSinglePrices from './Components/Prices/PriceSheets/AssesmentSingleNumber';
import VolumeWeightedIndex from './Components/Prices/PriceSheets/VolumeWeightedIndex';
import VolumeWeightedAverage from './Components/Prices/PriceSheets/VolumeWeightedAverage';
import VolumeWeightedAverageInReview from './Components/Prices/PriceSheets/VolumeWeightedAverageInReview';
import VolumeWeightedAverageEditor from './Components/Prices/PriceSheetEditor/VolumeWeightedAverageEditor';
// import TraditionalIndex from './Components/Prices/PriceSheets/TraditionalIndex';
// import BulkScrapIndex from './Components/Prices/PriceSheets/BulkScrapIndex';
import CalculatedIndex from './Components/Prices/PriceSheets/CalculatedIndex';

import SourcesDetails from './Components/Prices/PriceSheets/SourcesDetails';

import { check_session_expiry } from './store/actions/authAction';

if( localStorage.token ){
  check_session_expiry();
}


const App = () => {
  return (
    <Provider store={ store }>
      <Router>
        <Switch>
            <Route exact path='/reset-password/:id' component={ ResetPassword } />
            <Route exact path='/email-verification/:id' component={ EmailVerification } />
            <Route exact path='/forget-password' component={ ForgetPassword } />
            <Route exact path='/' component={ Login  } />
            <Route exact path='/health' component={HealthCheck} />
            <PrivateRoute exact path='/profile' component={ Profile } />
            <PrivateRoute exact path='/profile/super-admin' component={ SuperAdmin } />
            <PrivateRoute exact path='/profile/sub-admins' component={ SubAdminList } />
            <PrivateRoute exact path='/profile/calendar' component={ Calendar } />
            <PrivateRoute exact path='/profile/tags' component={ Tags } />
            <PrivateRoute exact path='/news-letter' component={ NewsLetter } />

            <PrivateRoute exact path='/adminstrator/editorials/journalists' component={ AdministratorEditorialJournalist } />
            <PrivateRoute exact path='/adminstrator/editorials/pricingreporters' component={ AdministratorEditorialPricingReporter } />
            <PrivateRoute exact path='/adminstrator/editorials/email' component={ AdministratorEditorialEmails } />

            <PrivateRoute exact path='/adminstrator/methodology' component={ Methodology } />
            <PrivateRoute exact path='/adminstrator/sources/active' component={ ActiveSources } />
            <PrivateRoute exact path='/adminstrator/sources/archive' component={ ArchiveSources } />
            <PrivateRoute exact path='/adminstrator/sources/person/:id' component={ SourcePerson } />

            <PrivateRoute exact path='/adminstrator/markets/active' component={ MarketActive } />
            <PrivateRoute exact path='/adminstrator/markets/archives' component={ MarketArchives } />
            <PrivateRoute exact path='/adminstrator/markets/notices' component={ MarketNotices } />
            <PrivateRoute exact path='/adminstrator/markets/add-price' component={ MarketAddPrice } />
            <PrivateRoute exact path='/adminstrator/markets/update-price/:id' component={ MarketUpdatePrices } />

            <PrivateRoute exact path='/adminstrator/customers/active' component={ ActiveOrganisationCustomer } />
            <PrivateRoute exact path='/adminstrator/customers/archive' component={ ArchiveOrganisationCustomer } />
            <PrivateRoute exact path='/adminstrator/customers/organisation-details/:id' component={ SingleOrgansationCustomer } />
            <PrivateRoute exact path='/adminstrator/customers/add-user' component={ addUser } />
            <PrivateRoute exact path='/adminstrator/customers/paid-user' component={ PaidUser } />
            <PrivateRoute exact path='/adminstrator/customers/trial-user' component={ TrialUser } />
            <PrivateRoute exact path='/adminstrator/customers/archive-user' component={ ArchivedUser } />
            <PrivateRoute exact path='/adminstrator/customers/expired-user' component={ ExpiredUser } />
            <PrivateRoute exact path='/adminstrator/customers/referral' component={ ReferralCode } />
            <PrivateRoute exact path='/adminstrator/customers/single-user/:redirectPath/:id' component={ SingleUser } />
            <PrivateRoute exact path='/adminstrator/customers/single-user-deleted/:redirectPath/:email' component={ ExpiredSingleUser } />
          <PrivateRoute exact path='/adminstrator/customers/referral-single-user/:redirectPath/:id/:referral_code' component={ReferralSingleUser} />
          
          <PrivateRoute exact path="/adminstrator/customers/promotion-codes" component={GetAllPromotionCodes} />

            <PrivateRoute exact path='/adminstrator/tradelogs' component={ ManageTradelog } />
            <PrivateRoute exact path='/adminstrator/tradelogs/view/:id' component={ TradelogView } />

            <PrivateRoute exact path='/adminstrator/update-link/methodology' component={ UpdateMethodology } />

            <PrivateRoute exact path='/adminstrator/super-editor' component={ SuperEditors } />
            
            <PrivateRouteTwo exact path='/posts/authors/:id' component={ AuthorPostArea } />
            <PrivateRouteTwo exact path='/posts/editors/:id' component={ EditorPostArea } />

            <PrivateRoute exact path='/editorial/author/:articleType/draft' component={ DraftHOC } />
            <PrivateRoute exact path='/editorial/author/:articleType/published' component={ PublishedHOC } />
            
            <PrivateRoute exact path='/editorial/editor/:articleType/inreview' component={ InReviewHOC } />
            <PrivateRoute exact path='/editorial/editor/:articleType/published' component={ MyWorkHOC } /> 
            <PrivateRoute exact path='/editorial/editor/:articleType/edit-all' component={ EditHOC } />

            <PrivateRoute exact path='/designer/published' component={ DesignerPublishedData } />
            <PrivateRoute exact path='/designer/view/:id' component={ DesignedViewData } />

            {/* All Stories  */}
            <PrivateRoute exact path='/allStories' component={ AllStories } />
            <PrivateRoute exact path='/prices/author/tradelogs' component={ AuthorTradeLog } />
            <PrivateRoute exact path='/prices/author/in-review' component={ AuthorInReview } />
            <PrivateRoute exact path='/prices/author/view-in-review/:id' component={ ViewInReview } /> 
            <PrivateRoute exact path='/prices/author/publishedprices' component={ AuthorPublishedPrices } />
            <PrivateRoute exact path='/prices/author/:id' component={ AuthorPublishedList } /> 
            <PrivateRoute exact path='/prices/author/date/:trade_log_id/:startDate' component={ AuthorPublishedViewDate } /> 
            <PrivateRoute exact path='/prices/sources/active' component={ PriceActiveSources } />
            <PrivateRoute exact path='/prices/sources/archive' component={ PriceArchiveSources } />
            <PrivateRoute exact path='/prices/sources/person/:id' component={ PriceSourcePerson } />
            <PrivateRoute exact path='/prices/editor/in-review' component={ PriceEditorInReview } />
            <PrivateRoute exact path='/prices/super-editor' component={ SuperEditor } />
            <PrivateRoute exact path='/prices/excel-publish/:id' component={ ExcelSheetTradelog } />
            {/* <PrivateRoute exact path='/prices/editor/publishedprices' component={ PriceEditorPublished } /> */}
            <PrivateRoute exact path='/prices/archives' component={ PriceArchives } /> 
            <PrivateRoute exact path='/prices/archives/:id' component={ PriceArchivesList } /> 
            <PrivateRoute exact path='/prices/archives/:trade_log_id/:dpc_number' component={ PriceArchivesView } /> 
            <PrivateRoute exact path='/prices/archives/date/:trade_log_id/:startDate' component={ PriceArchivesViewDate } /> 

          {/* PRICE SHEETS */}
            <PrivateRoute exact path='/prices/pricesheet/assesmentrange/:id' component={ AssesmentRange } />
            <PrivateRoute exact path='/prices/pricesheet/in-review/:id' component={ PriceAuthorInReview } />
            <PrivateRoute exact path='/prices/pricesheet/editor/assesmentrange/:id' component={ AssesmentRangeEditor } />
            <PrivateRoute exact path='/prices/pricesheet/volumeweightedaverage/:id' component={ VolumeWeightedAverage } />
            <PrivateRoute exact path='/prices/pricesheet/volume-weighted-index/:id' component={ VolumeWeightedIndex } />
            <PrivateRoute exact path='/prices/pricesheet/calculated-index/:id' component={ CalculatedIndex } />
            <PrivateRoute exact path='/prices/pricesheet/volumeweightedaverageInReview/:id' component={ VolumeWeightedAverageInReview } />


            <PrivateRoute exact path='/prices/sources/:id' component={ SourcesDetails } />



            {/* <PrivateRoute exact path='/prices/pricesheet/assesmentsinglenumber/:id' component={ AssesmentSinglePrices } />
            
            <PrivateRoute exact path='/prices/pricesheet/volumeweightedindex/:id' component={ VolumeWeightedIndex } />
            <PrivateRoute exact path='/prices/pricesheet/traditionalindex/:id' component={ TraditionalIndex } />
            <PrivateRoute exact path='/prices/pricesheet/bulkscrapindex/:id' component={ BulkScrapIndex } />*/}
          {/* PRICE SHEETS */}

        </Switch>
      </Router>
    </Provider>
  )
}

export default App
