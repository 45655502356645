import Validator from "validator";
import isEmpty from "../../../utils/isEmpty";
import store from '../../../store/store';
import { GET_ERRORS } from '../../../store/types';

export const deleteTagValidator = (tagName) => {
  let errors = {};

  tagName = !isEmpty(tagName) ? tagName : "";

  if (!Validator.isLength(tagName, { min: 2, max: 30 })) {
    errors.tagName = "Tag Name must be between 2 to 30 characters";
  }

  if (!isEmpty(errors)) {
    store.dispatch({ type : GET_ERRORS, payload : errors });
    return false;
  } else {
    return true;
  }

}

export const updateTagValidator = (data) => {
  let errors = {};

  data.tagName = !isEmpty(data.tagName) ? data.tagName : "";
  data.newTagName = !isEmpty(data.newTagName) ? data.newTagName : "";

  if (!Validator.isLength(data.tagName, { min: 2, max: 30 })) {
    errors.tagName = "Tag Name must be between 2 to 30 characters";
  }

  if (!Validator.isLength(data.newTagName, { min: 2, max: 30 })) {
    errors.newTagName = "New Tag Name must be between 2 to 30 characters";
  }

  if (!isEmpty(errors)) {
    store.dispatch({ type : GET_ERRORS, payload : errors });
    return false;
  } else {
    return true;
  }
}

export const tagsValidator = (data) => {
  let errors = {};
  data.parent = !isEmpty(data.parent) ? data.parent : "";
  data.child = !isEmpty(data.child) ? data.child : "";
  
  data.assigned_publications = !isEmpty(data.assigned_publications)
    ? data.assigned_publications
    : [];

  if (!Validator.isLength(data.parent, { min: 2, max: 30 })) {
    errors.parent = "Parent Name must be between 2 to 30 characters";
  }
  
  if (Validator.isEmpty(data.parent)) {
    errors.parent = "Parent Name is required !!!";
  }

  if (!Validator.isLength(data.child, { min: 2, max: 30 })) {
    errors.child = "Child Name must be between 2 to 30 characters";
  }

  if (data.assigned_publications.length === 0) {
    errors.assigned_publications = "Please select one publications";
  }

  if (!isEmpty(errors)) {
    store.dispatch({ type : GET_ERRORS, payload : errors });
    return false;
  } else {
    return true;
  }
};
