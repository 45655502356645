import axios from "axios";
import {
  SET_TAGS,
  UPDATE_TAG,
  DELETE_TAG,
  CREATE_TAG,
  CLEAR_LOADER,
} from "../types";
import { setErrors } from "./errorAction";
import { SERVER_NAME, SERVER_TWO } from "../../ServerUrl";

export const getTags = () => async (dispatch) => {
  try {
    let { data } = await axios.get(`${SERVER_NAME}/admin/categories/`);
    if (data) {
      dispatch({ type: SET_TAGS, payload: data });
    }
  } catch (err) {
    dispatch({ type: CLEAR_LOADER });
    dispatch(setErrors(err));
  }
};

export const createTag = (payload) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_TAG });
    let { data } = await axios.post(`${SERVER_NAME}/admin/categories/create`, payload);
    if (data) {
      dispatch({ type: CLEAR_LOADER });
    }
    return true;
  } catch (err) {
    dispatch({ type: CLEAR_LOADER });
    dispatch(setErrors(err));
  }
};

export const updateTag = (payload, id) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_TAG });
    let { data } = await axios.put(`${SERVER_NAME}/admin/categories/update/${id}`, payload);
    if (data.success) {
      dispatch({ type: CLEAR_LOADER });
    }
  } catch (err) {
    dispatch({ type: CLEAR_LOADER });
    dispatch(setErrors(err));
  }
};

export const deleteTag = (payload) => async (dispatch) => {
  
  try {
    dispatch({ type: DELETE_TAG });
    let { data } = await axios.post(`${SERVER_NAME}/admin/categories/delete`, payload);
    if (data) {
      dispatch({ type: CLEAR_LOADER });
    }
    return true;
  } catch (err) {
    dispatch({ type: CLEAR_LOADER });
    dispatch(setErrors(err));
    return false;
  }
};
